.rc-slider-rail {
    background-color: theme(colors.white);
}

.rc-slider-track {
    background-color: theme(colors.primary);
}

.rc-slider-handle {
    border-color: theme(colors.primary);
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: theme(colors.primary);
    box-shadow: 0 0 0 2px theme(colors.dark);
}

.rc-slider-handle:focus {
    outline: none;
    box-shadow: none;
}

.rc-slider-handle:focus-visible {
    border-color: theme(colors.dark);
    box-shadow: 0 0 0 1px theme(colors.dark);
}

.rc-slider-handle .rc-slider-handle-click-focused:focus {
    border-color: theme(colors.primary);
    box-shadow: unset;
}

.rc-slider-handle:hover {
    border-color: theme(colors.primary);
}

.rc-slider-handle:active {
    border-color: theme(colors.primary);
    box-shadow: 0 0 2px theme(colors.primary);
}

.rc-slider-dot {
    border-color: theme(colors.gray.400);
}

.rc-slider-dot-active {
    border-color: theme(colors.primary);
}

.rc-slider-tooltip-inner {
    background-color: theme(colors.secondary);
    padding-left: theme(padding.2);
    padding-right: theme(padding.2);
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    border-top-color: theme(colors.secondary);
}
